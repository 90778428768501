<template>
  <div>
    <app-collapse
      hover
      v-if="$can('Zircly Admin') && employeeListOptions.length"
    >
      <app-collapse-item title="Filters">
        <b-card no-body>
          <b-row>
            <!-- <b-col cols="12" md="4" sm="6" class="mb-md-0 mb-2">
              <label>Status</label>
              <v-select
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                v-model="filterByType"
                class="w-100"
                :options="typeOption"
                label="label"
                :reduce="(val) => val.code"
                placeholder="Select Type.."
              />
            </b-col> -->
            <b-col cols="12" md="4" sm="6" class="mb-md-0 mb-2">
              <div v-if="$can('Zircly Admin') && employeeListOptions.length">
                <label>Employee</label>
                <v-select
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  v-model="userHashid"
                  :options="employeeListOptions"
                  class="w-100"
                  :reduce="(val) => val.code"
                  :clearable="false"
                  placeholder="Select Employee.."
                />
              </div>
            </b-col>
          </b-row>
        </b-card>
      </app-collapse-item>
    </app-collapse>

    <b-card class="mb-1 mt-2">
      <div class="card-title d-flex justify-content-between align-items-center">
        Set Email Notification Subscriptions

        <b-input-group class="input-group-merge round w-25">
          <b-input-group-prepend is-text>
            <feather-icon icon="SearchIcon" class="text-muted" />
          </b-input-group-prepend>
          <b-form-input
            v-model="searchQuery"
            class="d-inline-block"
            placeholder="Search Name"
          />
        </b-input-group>

        <b-card-text>
          All Notification ({{ subscriptions.length }})

          <b-form-checkbox
            v-model="all_notifications"
            switch
            inline
            @change="updateAllSubscription()"
          >
            <span class="switch-icon-left">
              <feather-icon icon="BellIcon" />
            </span>
            <span class="switch-icon-right">
              <feather-icon icon="BellOffIcon" />
            </span>
          </b-form-checkbox>
        </b-card-text>
      </div>

      <b-list-group>
        <b-list-group-item
          v-for="module in modules"
          v-bind:key="module.label"
          class=""
          v-if="moduleNotifications(module.label).length"
        >
          <div class="font-weight-bold mb-1">
            {{ capitalizeFirstLetter(module.label) }}
          </div>
          <b-list-group>
            <b-list-group-item
              v-for="subscription in moduleNotifications(module.label)"
              v-bind:key="subscription.id"
              class="d-flex justify-content-between1 align-items-center border-0 pl-0"
              :disabled="subscription.is_mandatory ? true : false"
            >
              <b-form-checkbox
                switch
                inline
                :checked="subscription.subscribed ? true : false"
                :disabled="subscription.is_mandatory ? true : false"
                @change="
                  updateSubscription(
                    subscription.id,
                    subscription.subscribed ? false : true
                  )
                "
              >
                <span class="switch-icon-left">
                  <feather-icon icon="BellIcon" />
                </span>
                <span class="switch-icon-right">
                  <feather-icon icon="BellOffIcon" />
                </span>
              </b-form-checkbox>
              <div class="">
                {{ subscription.name }}
                <div class="badge badge-success badge-pill">
                </div>
              </div>
            </b-list-group-item>
          </b-list-group>
        </b-list-group-item>
      </b-list-group>

      
      <div v-if="!subscriptions.length" class="text-center">
        There were no results that matched.
      </div>
    </b-card>
  </div>
</template>

<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import vSelect from "vue-select";

import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BForm,
  BFormCheckbox,
  BInputGroup,
  VBTooltip,
  BInputGroupAppend,
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import { onUnmounted } from "@vue/composition-api";
import { required, email, numeric } from "@validations";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import store from "@/store";
import emailNotificationStoreModule from "./emailNotificationStoreModule";
import bomb from "@/libs/bomb/bomb";
import StarRating from "vue-star-rating";
import formValidation from "@core/comp-functions/forms/form-validation";
import axios from "@axios";
import Vue from "vue";
import VueCookies from "vue-cookies";
Vue.use(VueCookies);

export default {
  components: {
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BForm,
    BFormCheckbox,
    BInputGroupAppend,
    BInputGroup,
    vSelect,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    StarRating,
    flatPickr,
    AppCollapse,
    AppCollapseItem,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      all_notifications: false,
      allSelected: false,
      is_loading: {
        submit: false,
      },
      redirectEmailNotification: "",
      data_local: {
        title: "",
        get_customfields_data: [],
      },
      subscriptions: [],
      modules:[],
      employeeListOptions: [],
      userHashid: this.$cookies.get("userData")["hashid"],
      searchQuery: "",
      filterByType: "",
      typeOption: [
        {
          code: "one",
          label: "One",
        },
        {
          code: "many",
          label: "Many",
        },
      ],
    };
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = "app-email-notifications";

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(
        USER_APP_STORE_MODULE_NAME,
        emailNotificationStoreModule
      );

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });
    const { refFormObserver, getValidationState, resetForm } = formValidation();

    return {
      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
  created() {
    this.getUserEmailSubscriptions();
    this.fetchEmployeeList();
    this.getModules();
  },
  watch: {
    searchQuery(val) {
      this.getUserEmailSubscriptions();
    },
    filterByType(val) {
      this.getUserEmailSubscriptions();
    },
    userHashid(val) {
      this.getUserEmailSubscriptions();
    },
  },
  methods: {
    capitalizeFirstLetter(str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
    getModules(){
      axios.get("/list/modules").then((response) => {
        if (response.data.success) {
          this.modules = response.data.data;
        } else {
          this.$toast.error(response.data.message);
        }
      });
    },
    moduleNotifications(module){
      var data = [];
      for (let index = 0; index < this.subscriptions.length; index++) {
        var subscription = this.subscriptions[index];
        if(subscription.module_name == module){
          data.push(subscription);
        }
      }
      return data;
    },
    fetchEmployeeList() {
      this.$store
        .dispatch("app/fetchEmployeeList")
        .then((res) => {
          this.employeeListOptions = res.data.data;
        })
        .catch((err) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "fetch Employee List failed.",
              icon: "BellIcon",
              variant: "danger",
              text: err.toString(),
            },
          });
        });
    },
    updateSubscription(id, subscribed) {
      
      let params = [];
      params.id = id;
      params.subscribed = subscribed;
      params.hashid = this.userHashid;

      this.$store
        .dispatch("app-email-notifications/updateSubscription", params)
        .then((res) => {
          if (res.status == "202") {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Subscription Updated",
                icon: "BellIcon",
                variant: "success",
              },
            });
            this.getUserEmailSubscriptions();
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Subscription Updated",
                icon: "BellIcon",
                variant: "danger",
                text: res.data.message,
              },
            });
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    updateAllSubscription() {
      
      let params = [];
      params.subscribed = this.all_notifications;
      params.hashid = this.userHashid;
      this.$store
        .dispatch("app-email-notifications/updateAllSubscription", params)
        .then((res) => {
          if (res.status == "202") {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Subscription Updated",
                icon: "BellIcon",
                variant: "success",
              },
            });
            this.getUserEmailSubscriptions();
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Subscription Updated",
                icon: "BellIcon",
                variant: "danger",
                text: res.data.message,
              },
            });
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    formSubmitted() {
      
      const formData = new FormData(
        document.getElementById("email-notification-form")
      );

      formData.append("experience", this.data_local.experience);
      formData.append("status", this.data_local.status);
      formData.append("type", this.data_local.emailNotification_type);

      this.is_loading.submit = true;
      this.$store
        .dispatch("app-email-notifications/addEmailNotification", formData)
        .then((res) => {
          // Add this email-notification to NHR
          this.is_loading.submit = false;
          this.$router.push("/jobs");
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "EmailNotification Added",
              icon: "BellIcon",
              variant: "success",
              text: res.data.message,
            },
          });
        })
        .catch((error) => {
          this.is_loading.submit = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Oops! EmailNotification Adding Failed",
              icon: "BellIcon",
              variant: "danger",
              text: error,
            },
          });
        });
    },
    validationForm() {
      

      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    getUserEmailSubscriptions() {
      
      let params = [];
      params.q = this.searchQuery;
      params.type = this.filterByType;
      params.hashid = this.userHashid;
      this.$store
        .dispatch("app-email-notifications/getUserEmailSubscriptions", params)
        .then((res) => {
          this.subscriptions = res.data.email_notifications;

          let allSubscribed = this.subscriptions.filter(function (
            subscription
          ) {
            return subscription.subscribed === 1;
          });
          this.all_notifications =
            allSubscribed.length == this.subscriptions.length ? true : false;
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Oops! Custom Fields Getting Failed",
              icon: "BellIcon",
              variant: "danger",
            },
          });
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
// Import FilePond styles
@import "~filepond/dist/filepond.min.css";
@import "~filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
@import "@core/scss/vue/pages/page-auth.scss";

.filepond--credits {
  display: none;
}
</style>

<style lang="scss" scoped>
.assign-interview-btn {
  position: absolute;
  right: 180px;
  bottom: 60px;
  border: solid 1px;
}
span.rating-btn {
  width: 130px;
  margin-left: 5px;
  padding: 2px;
  color: #fff;
  border-radius: 2px;
  font-size: 13px;
  text-align: center;
  font-weight: 700;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  line-height: 25px;
}
.star-belowAverage {
  background: #f5c357;
}
.star-poor {
  background: #bababa;
}
.star-average {
  background: #f3d23e;
}
.star-good {
  background: #c1d759;
}
.star-excellent {
  background: #48964d;
}
.list-group-item {
  background: transparent;
}
.email-setting {
  -webkit-column-count: 3;
  -moz-column-count: 3;
  column-count: 3;
  display: block;
}
@media screen and (max-width: 767px) {
  .email-setting {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
    display: block;
  }
}
@media screen and (max-width: 480px) {
  .email-setting {
    -webkit-column-count: 1;
    -moz-column-count: 1;
    column-count: 1;
    display: block;
  }
}
</style>
